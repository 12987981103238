
.bar-title{
    content: "";
    display: block;
    width: 50px;
    height: 4px;
    background: #20c20c;

}
section{
    padding-top: 50px;
  }
h2{
    color: #000;
}
html{scroll-behavior: smooth;}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
.mat-radio-button.mat-accent .mat-radio-inner-circle{
    background-color: #20c20c!important;
}
.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle{
    border-color: #20c20c!important;
}
.mat-radio-outer-circle{
    border-color: white!important;
}
.vf-style {
    background-color: #20c20c !important;
    color: white;
}
.vf-style-secondary {
    background-color: #000 !important;
    color: white;
}
.text-vf {
    color: #20c20c;
}