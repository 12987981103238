.btn-primary {
  background-color: #20c20c;
  border-color: #20c20c;
}

a {
  color: #20c20c;
}

.badge {
  font-weight: 400;
}